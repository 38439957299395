import React from 'react';
import RoleSection from './RoleSection';

function CompanySection(props) {
  return (
    <div>
      <div class="row">
        <div class="col-lg-12">
          <h3 class="exp-sub-heading-blue">{props.company.companyTitle}</h3>
          <p>{props.company.companyDescription}</p>
          {props.company.roles.map((role, index) => {
          return (
            <div>
              <RoleSection
                key={index}
                id={index}
                role={role}
              />
              {index + 1 !== props.company.roles.length ? <hr class="portfolio-sub-divider" /> : null}
            </div>
          );
        })}
        </div>
      </div>
    </div>
  );
}
export default CompanySection;

const volunteerRoles = [{
    id: "v1",
    companyTitle: "PEGS Hockey Club",
    companyDescription: "PEGS Hockey Club is an inclusive family orientated club, with a proud history within the Hockey Victoria competition. Partially affiliated with local school, Penleigh and Essendon Grammar, players from within the school and the broader community are welcome.",
    roles: [{
        id: "vb1",
        title: "Committee Member",
        period: "January 2016 - Current",
        description: "As part of PEGS HC Committee I am able to give back to the club that I have been a part of since 2008. My role at the club is focused on improvements in process and operations using technology. ur product roadmap for the club has a few more years of work outlined which I hope I can continue to contribute to.",
        responsibilities: [
          "Maintain IT Infrastructure such as Point of Sale, Website, Tracking sheets",
          "Deliver new features and enhancements to IT Systems to improve oprations",
          "Manage Domains, hosting, email accounts"
        ],
        achievements: [
          "During my time at the club, I have led two re-designs of the club's website. Firstly, an overhaul of the site keeping it as a static web-page. More recently, I have deployed the website as a web application, allowing us to build features into the site, forgoing reliance on third party services or manual book-keeping.",
          "Overhauled the canteen, transforming the cash-only, manual operation to a revenue generator with card payments, digital stock-lists and analytics into popular items.",
          ""
      ]
      }
    ]
  }
];

export default volunteerRoles;

import React, { useEffect } from "react";
import TitleSection from "../TitleSection";
import Skills from "./Skills";
import Testimonials from "./Testimonials";
import Contact from "./Contact";
import Footer from "../Footer";
import { getYear } from "../../date";

function Index() {
  useEffect(() => {
    document.title = "James Fitch | Geek";
  }, []);

  return (
    <div>
      <TitleSection
        page="index"
      />
      <Skills />
      <Testimonials />
      <Contact />
      <Footer
        year={getYear()}
      />
    </div>
  );
}

export default Index;

//jshint esversion:6

function getDate() {

  const today = new Date();

  const options = {
    weekday: "long",
    day: "numeric",
    month: "long"
  };

  return today.toLocaleDateString("en-US", options);

};

function getDay() {

  const today = new Date();

  const options = {
    weekday: "long"
  };

  return today.toLocaleDateString("en-US", options);

};

function getYear() {

  const today = new Date();

  const options = {
    year: "numeric"
  };

  return today.toLocaleDateString("en-US", options);

};
export {getDate, getDay, getYear};

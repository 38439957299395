import React, { useEffect } from "react";
import TitleSection from "../TitleSection";
import Projects from './Projects';
import Footer from "../Footer";
import { getYear } from "../../date";

function Portfolio() {
  useEffect(() => {
    document.title = "What I've Done";
  }, []);

  return (
    <div>
      <TitleSection
        page="portfolio"
      />
      <Projects />
      <Footer 
        year={getYear()}
      />
    </div>
  );
}

export default Portfolio;

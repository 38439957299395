const projectItems = [
  {
    id: "a1a",
    heading: "B-Fest",
    subHeading: "Here for a good time not a long time",
    description: "B-Fest is a 24 hour movie marathon hosted annually. The iOS and web app both provide the same functionality; authentication, the ability to vote for the Viewers' Choice movie, and ability to partake in a game of Bingo for prizes.<br/><br/>For the website, the backend and authentication is handled in Firebase as is hosting. The front end is coded with the standard triage of HTML, CSS, JavaScript. The iOS Application was built in Swift using cocoapods for added functionality.",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "jQuery",
      "Firebase",
      "Hosting",
      "Swift",
      "UIKit",
      "Auth",
      "Dark Mode"
    ],
    sourceURLs: [
      {
          buttonTitle: "Download on the App Store",
          url: "https://apps.apple.com/au/app/b-fest/id1430673830",
      },
      {
          buttonTitle: "Check out the website",
          url: "https://bfest.app"
      }
    ],
    mediaURLs: [
      "video/b-fest-ios-demo.mp4",
      "video/b-fest-web-demo.mp4"
    ]
  },
  {
    id: "l1l",
    heading: "Adam and Eve",
    subHeading: "The App made to be deleted",
    description: "Adam and Eve is a revolutionary dating app. Users are matched based on their preferences with one twist. The only possible match is my friend Adam. Allowing users to meet \"The One\".",
    skills: [
      "MVVM",
      "Firebase",
      "Full Stack",
      "Gesture Recognisers",
      "Resolving Retain Cycles",
      "Push Notificatons",
      "Animations",
      "Hosting Views",
      "SwiftUI",
      "UIKit",
      "Auth",
      "Dark Mode"
    ],
    sourceURLs: [
      {
          buttonTitle: "Download on the App Store",
          url: "https://apps.apple.com/au/app/adam-and-eve/id1522431765",
      },
      {
          buttonTitle: "Check out the website",
          url: "https://adamandeve.app"
      }
    ],
    mediaURLs: [
      "video/adam-and-eve-demo.mov",
    ]
  },
  {
    id: "b2b",
    heading: "PEGS Hockey Club Website",
    subHeading: "",
    description: "As part of my role as the CTO of PEGS Hockey Club I have been working to consolidate the club's operational requirements into one consolidated system to reduce administrative overhead. The website currently allows members to sign-up to the club and place uniform/merch orders. Members have a profile which provides the club with a centralised repository of all the information required of the player.<br><br>The site is built using Express JS and hosted through Firebase which handles the backend and authentication. As a single page website, the site was previously hosted on AWS.",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "jQuery",
      "Express JS",
      "NPM",
      "Firebase",
      "Heroku",
      "Hosting",
      "Auth"
    ],
    sourceURLs: [
      {
          buttonTitle: "Check out the website",
          url: "http://www.pegshockeyclub.com.au"
      }
    ],
    mediaURLs: [
      "video/pegs-web-demo.mp4"
    ]
  },
  {
    id: "covid1c",
    heading: "COVID-19 Daily Tracking App",
    subHeading: "Country stats at your fingertips",
    description: "App built using SwiftUI. Service class establishes a URL Shared Session which sends a request to a GitHub repo maintianed by John Hopkins University. Data is returned in raw CSV format which is converted into local model structs using the View Model class. Data is displayed in graph and table format for the top 10 countries as well as a table of data for Australia. All data is maintinaed and the top 10 and Australia data is exrtacted by a reduce method allowing new featues to be added if required.",
    skills: [
      "SwiftUI",
      "API Request",
      "URL Shared Session",
      "CSV to Object",
      "MVVM",
      "Geometry Reader"
    ],
    sourceURLs: [
      {
          buttonTitle: "Check out the source code",
          url: "https://github.com/fitchatron/COVID-19"
      }
    ],
    mediaURLs: [
      "video/covid-infections-demo.mov"
    ]
  },
  {
    id: "c2c",
    heading: "React Note Taking App",
    subHeading: "Clone of the Google Keep App",
    description: "A clone of the Google Keep App. Built using the create-react-app tool from Facebook and styled using Material-UI",
    skills: [
      "React",
      "Material-UI",
      "Create-React-App"
    ],
    sourceURLs: [
      {
          buttonTitle: "Check out the source code",
          url: "https://github.com/fitchatron/Keeper-React-Note-App"
      }
    ],
    mediaURLs: [
      "video/keeper-clone-demo.mov"
    ]
  },
  {
    id: "d2d",
    heading: "Maven Admin App",
    subHeading: "For General Motors",
    description: "The Maven Admin App was built to allow staff in Maven Australia to perform vehicle inspections with customers on the delivery and return of vehicles. The account used email authentication limited to GM email accounts and stored inspections using Firebase's realtime database. The inspections could then be accessed and updated on any GM iPad nationally. Notable feature was the ability to export the UIView's window as an image and email it to the operations team as a means of back-up.",
    skills: [
      "Swift",
      "UIKit",
      "TestFlight",
      "Firebase",
      "Auth",
      "DispatchGroup"
    ],
    sourceURLs: [
      {
          buttonTitle: "Request a deeper dive",
          url: "/#contact"
      }
    ],
    mediaURLs: [
      "video/maven-admin-demo.mp4"
    ]
  },
  {
    id: "e2e",
    heading: "Hotdog Notdog",
    subHeading: "What could it be?",
    description: "Like the See-Food app on the HBO show Silicon Valley, this app used a pre-trained model to detect images via the Photo Library or the camera and classify it.<br><br>The feature is built twice in the app; once in UIKit and once in SwiftUI.",
    skills: [
      "Swift",
      "SwiftUI",
      "UIKit",
      "Vision",
      "Core ML",
      "Image Picker"
    ],
    sourceURLs: [
      {
          buttonTitle: "Check out the source code",
          url: "https://github.com/fitchatron/Hotdog-Notdog"
      }
    ],
    mediaURLs: [
      "video/hotdog-demo.mp4"
    ]
  },
  {
    id: "f2f",
    heading: "Colour Quiz",
    subHeading: "Great time-waster on long haul flights",
    description: "A brain teaser which randomly generates two colours. Your aim is to select the colour corresponding to the word displayed. The second colour is set as that label's background colour to confuse you.",
    skills: [
      "Swift",
      "SwiftUI",
      "Structs",
      "Classes"
    ],
    sourceURLs: [
      {
          buttonTitle: "Request a deeper dive",
          url: "index.html#contact"
      }
    ],
    mediaURLs: [
      "video/colour-quiz-demo.mp4"
    ]
  },
  {
    id: "g2g",
    heading: "App Store Clone",
    subHeading: "Curated clone",
    description: "A clone of the Apple App store. App uses the iTunes API to query search results. DispatchGroup ensures that data loads on different queues are synchonrised and UI refreshed once all items have finished executing.",
    skills: [
      "Swift",
      "UIKit",
      "APIs",
      "DispatchGroup",
      "UITableView Diffable Data Source"
    ],
    sourceURLs: [
      {
          buttonTitle: "Check out the source code",
          url: "https://github.com/fitchatron/App-Store-Clone"
      }
    ],
    mediaURLs: [
      "video/app-store-clone-demo.mp4"
    ]
  },
  {
    id: "h2h",
    heading: "Maps Project",
    subHeading: "Where is it?",
    description: "App uses MKMapKit to perform basic mapping functions such as search and directions.",
    skills: [
      "Swift",
      "SwiftUI",
      "MKMapKit",
      "Directions",
      "JSON",
      "Location Search"
    ],
    sourceURLs: [
      {
          buttonTitle: "Check out the source code",
          url: "https://github.com/fitchatron/Maps-Project"
      }
    ],
    mediaURLs: [
      "video/maps-project-demo.mp4"
    ]
  },
  {
    id: "i2i",
    heading: "Blog Site",
    subHeading: "Check out my blog. I'm almost an influencer!",
    description: "A simple web app that allows you to view blog posts as a summary. Clicking on a post will dynamically create a detail page with the full post. EJS is used to dynamically generate pages a compose page exists to allow new posts to be entered.",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "Express JS",
      "EJS",
      "Views",
      "NPM",
      "Bootstrap",
      "MongoDB",
      "Mongoose"
    ],
    sourceURLs: [
      {
          buttonTitle: "Check out the source code",
          url: "https://github.com/fitchatron/ejs-blog"
      }
    ],
    mediaURLs: [
      "video/blog-demo.mov"
    ]
  },
  {
    id: "j2j",
    heading: "Mailchimp Newsletter Signup",
    subHeading: "Web based proof-of-concept",
    description: "A simple web app that contains a form. On submission the form details will POST to a Mailchimp account's subscriber list.",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "Express JS",
      "APIs",
      "NPM",
      "Bootstrap"
    ],
    sourceURLs: [
      {
          buttonTitle: "Check out the source code",
          url: "https://github.com/fitchatron/mailchimp-newsletter"
      }
    ],
    mediaURLs: [
      "video/newsletter-demo.mp4"
    ]
  },
  {
    id: "k2k",
    heading: "Red Edge Wine",
    subHeading: "Production deployment",
    description: "Production website built for client. Site was built using a software service to achieve a quick deployment with easy extension features. Solution was chosen so that the owner could manage and update the site themselves without having to pay someone to do it. Site uses a basic template and plug-ins such as Shippit for couriering.<br><br>Solution illustrates my ability to develop solutions and features with the end user and overall intention in mind.",
    skills: [
      "Shopify",
      "SAAS",
      "Shopify Extensions"
    ],
    sourceURLs: [
      {
          buttonTitle: "Check out the website",
          url: "https://rededgewine.com.au"
      }
    ],
    mediaURLs: [
      "video/red-edge-demo.mp4"
    ]
  }
];

export default projectItems;
const roles = [{
    id: "a1",
    companyTitle: "Alfred Health",
    companyDescription: "Alfred Health is the main provider of health services to people living in the inner southeast suburbs of Melbourne and is also a major provider of specialist services to people across Victoria. The health service operates three outstanding facilities, The Alfred, Caulfield, and Sandringham.",
    roles: [{
        id: "b1",
        title: "Data Analyst - Costing and Performance",
        period: "March 2020 - Current",
        description: "The role is primarily responsible for looking after the clinical costing process within Alfred Health. This involves bringing together patient activity data with financial data, aiming to retrospectively determine the actual cost of care provided to patients.",
        responsibilities: [
          "Writing SQL queries to extract workforce data for loading into Alfred Health’s data warehouse",
          "Collaboratively submitting changes through Git platform Bitbucket via pull requests",
          "Managing project and development tasks through Jira",
          "Identify and implement improvements relating to the end-to-end costing process",
          "Proactively identify new data sources to continuously improve the quality of data available for clinical costing",
          "Review all data for completeness and accuracy; identify any quality issues",
          "Maintain various reference tables and business rules required for clinical costing"
        ],
        achievements: ["During the evolving COVID pandemic I have been fortunate enough to assist with creating dashboards for Workforce Planning once the pandemic escalates in Australia. I have been working"]
      },
      {
        id: "b2",
        title: "Business Analyst - Workforce Systems Development Project",
        period: "August 2019 - March 2020",
        description: "Working as Business Analyst – Workforce Systems Development Project, I am responsible for assessing the business and information needs of Alfred Health services and contribute to the design and development of business requirements that improve business efficiency and productivity; through the delivery of SAP Success Factors HRIS modules and Kronos rostering system upgrades.",
        responsibilities: [
          "Work collaboratively with internal services to analyse information needs and functional requirements and deliver project artefacts as needed. Such as business requirements, functional requirements, use cases, interface designs, test plans and test cases",
          "Facilitation and participation in project ceremonies throughout the project lifecycle",
          "Collaborate with Product Owners and IT vendors to establish the technical vision and analyse trade-offs between usability and performance needs",
          "Engineering tools to automate the query and cleansing of large data sets for system uploads",
          "Data analysis on system usability and system errors to drive future product features",
          "Work seamlessly with internal or external resources in the delivery of new solutions",
          "Performing gap analysis on current state and future state to provide clear context and steps forward on project deliverables",
          "Performing user acceptance testing and production validation testing on new deployments",
          "Prioritising and managing initiatives based on resources, business needs and requirements",
          "Provided change management support to business units by reviewing current processes and designing workflow improvements using new technical solutions",
          "Providing technical solution training to L1 and L2 Support Services",
          "Liaise with Product Owners to ensure Change Request and production sign-off documentation is completed for deployments",
          "Extracting and visualising Kronos workforce management data from Microsoft SQL server"
        ],
        achievements: [""]
      }
    ]
  },
  {
    id: "a2",
    companyTitle: "General Motors",
    companyDescription: "General Motors (GM), one of the world's largest auto manufacturers, makes cars and trucks, with well-known brands such as Holden, Buick, Cadillac, Chevrolet, and GMC, annually selling around 9.6 million vehicles throughout the world.",
    roles: [{
        id: "b11",
        title: "Business Performance Manager (Maven Australia)",
        period: "May 2018 - August 2019",
        description: "Maven is a mobility service offered by General Motors which provides customer access to vehicles for personal use or for use with ride-sharing platforms such as Uber. As BPM, my main responsibilities were working with our development projects within each area of Maven to deliver operational and cost saving efficiencies; enabling market share growth and revenue opportunities. This role was a hybrid of Project Management, Data Analyst, and Business Analyst.",
        responsibilities: [
          "Lead implementation of the Global Maven Platform for our ride-share rental business (Maven GIG) to enable cost saving, further market growth, and hundreds of hours of operation efficiencies a month",
          "Lead project migration of over 1500 customers from legacy system to Global Maven Platform",
          "Lead technical projects such as: Enhancements to Maven’s Support Centre’s Toll-Free Number to improve Customer Experience and operational metrics",
          "Lead technical projects such as: Insourcing customer email communications from Outlook to Siebel",
          "Writing complex SQL queries to interrogate Enterprise Data Warehouses for BAU activities",
          "Deep data analytics on KPIs of Maven’s performance and delivered to senior leadership and created live dashboards for the entire team to view",
          "Loading and modifying vehicle records in Maven’s Vehicle Master system using Python scripts and JSON data",
          "Lead team project to outsource operational work to a GM Shared Service Centre in Manila saving 2 FTE a week in resources and the related labour cost",
          "Lead project to reduce the insurance cost to our business by an upside of $100,000 a month since Jan 2019",
          "Lead project to fully automate billing at Maven Australia removing the need to have the work carried out. Included the designing of features now used globally in the Maven Mobile Application",
          "Managed and trained a team of four Business and Data Analysts",
          "Responsible for strategic data mapping of key business processes, Designing the “as-is” and “to-be” business process models",
          "Conducted workshops with business units to scope project requirements",
          "Working with GM’s Software Developers to verify and root cause bugs reported by business units",
          "Development, upkeep, and distribution of process guides and knowledge base articles",
          "Communicated project requirements and updates to stakeholders and engaged them throughout the project lifecycle",
        ],
        achievements: [
          ""
        ]
      },
      {
        id: "b22",
        title: "Maven Operations Manager (Maven Australia)",
        period: "March 2017 - May 2018",
        description: "As the Operations Manager for Maven Australia, I was responsible to setting up the Maven car sharing and ride-share rental business from scratch, designing and implementing a custom CRM, billing, and invoicing modules. I also created and architected each department’s non-technical processes, which is the basis for the entire operation today; prior to it's wind-down as Holden leaves Australia.",
        responsibilities: [
          "Month on month sales growth in the first 2 years of operation",
          "Reached revenue targets to achieve and sustain profitability in our business model in the first year; 4 years ahead of budget",
          "Successfully proved Maven concept in Australia gaining approval to take 30 car pilot to a mature business",
          "Designed scalable systems and processes to take our pilot team of 3 to a mature nation-wide team of over 30",
          "Lead Maven’s expansion into Sydney, Brisbane, Gold Coast, Sunshine Coast, Adelaide, and Perth",
          "Trained and managed a team of 8 Maven Gig Member Services employees",
          "Lead the expansion of Maven Gig nationally in Australia",
          "Developed relational databases for Maven Gig’s CRM and Vehicle Management solutions",
          "Developed, deployed, and maintained a native iOS app for vehicle collections, returns, and marketing events",
          "Developed reports and analytical points of interest to track key success indicators to report globally",
        ],
        achievements: [
          ""
        ]
      },
      {
        id: "b33",
        title: "Service Centre Coordinator",
        period: "January 2016 - March 2017",
        description: "Working as the Service Centre Coordinator for Holden’s HR department, I was tasked with finding automation efficiencies where manual, repetitive, and labour-intensive tasks could be replaced with technology. Enabling the transformation of the department during the closure of manufacturing and the transition to a National Sales Company.",
        responsibilities: [
          "Managed project to consolidate HR tasks in Australia to one centralised team and then outsource that work to a GM Shared Service Centre in Manila",
          "Managed local HR Service Centre team of 3 HR Consultants and 1 HR Data Analyst",
          "Business Analysis and Project Management on new HR systems in the Asia Pacific region for General Motors",
          "Process mapped existing systems and process improvements",
          "Performed User Acceptance Testing on system upgrades in pre-production phase",
          "Facilitated Excel and SharePoint training to employees at Holden locations with various skill levels from beginner to advanced",
        ],
        achievements: [
          ""
        ]
      },
      {
        id: "b44",
        title: "IT and HR IT Intern",
        period: "January 2014 - January 2016",
        description: "Working as an IT intern and then HR IT intern for General Motors Holden I was rotated through various IT departments in Holden gaining experience in hardware deployment and support, asset management, project management and Business Analyst work.",
        responsibilities: [
          "Providing L1 and L2 hardware and software support",
          "Process mapping Holden Australia and New Zealand's Aftersales application landscape",
          "Performed iPhone repairs on out of warranty units saving thousands of dollars in new hardware",
          "Writing HR Workforce reports in Cognos",
          "PeopleSoft Administrator for Australia and New Zealand",
          "SharePoint developer for departmental team sites",
          "Decommissioning Lotus Notes databases and transitioning those features to a SharePoint solution",
          "Quality Assurance testing on the myHolden iOS and Android applications",
        ],
        achievements: [
          ""
        ]
      },
    ]
  },
  {
    id: "a3",
    companyTitle: "My Mac Australia",
    companyDescription: "My Mac Australia is a Premium Apple Reseller and Service Provider. They provide sales and service support for all Apple devices.",
    roles: [{
        id: "b111",
        title: "Service Technician / Service Administrator",
        period: "March 2011 - December 2013",
        description: "Working up from the sales team, I became an Apple Certified Macintosh Technician; triaging issues with Mac and iOS devices for customers. In addition to repair work, I was also responsoble for performing the end of month service billing process to ensure that our repairs for the month reconciled with Apple parts and labour payments.",
        responsibilities: [
          "Performing the end of month reconciliation statements for all Victorian service centres. Ensuring all financial anomalies are accounted for so there are no discrepancies in totals",
          "Performing administrative duties such as uploading invoices from Apple into our management system and then invoicing to suppliers",
          "Performed on boarding and training of new staff and ensuring correct procedures are followed.",
          "Creating training manuals and videos",
          "Performing repairs on a range of Apple products from iPhones to Apple desktops and laptops",
          "Booking computers and iOS devices in for service or replacement and providing simple technical solutions for customers",
          "Opening and closing duties",
          "Finding specialised solutions for customers based on their individual needs",
          "Demonstrating the features and applications of Apple products to those new to the technology",
          "Converting technical terms into easy-to-understand terms",
          "Resolving customer grievances with sales/returns",
          "Preparing orders for customers",
          "Motivating staff to perform expected duties and reach sales targets",
        ],
        achievements: [""]
      }
    ]
  }
];

export default roles;

import React from 'react';
import Project from './Project';
import projectItems from './projectItems';

function Projects() {
  return (
    <div class="container pad-top">
      {projectItems.map((projectItem, index) => {
        return (
          <div>
            <Project
              item={projectItem}
              alignment={(index + 1) % 2 === 0 ? "trailing" : "leading"}
            />
            {index + 1 !== projectItems.length ? <hr class="portfolio-divider" /> : null}
            </div>
        )
      })}
    </div>
  );
}

export default Projects;

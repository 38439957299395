import React from "react";

function Navbar(props) {
  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-dark">
        <a class="navbar-brand" href="/"><img class={props.page === "index" ? "rounded-circle hero-image border border-white" : "rounded-circle hero-image"} src="img/hero.jpg" alt="James Fitch" /></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="navbar-nav ml-auto">
            <li class={props.page === "career" ? "nav-item active" : "nav-item"}>
              <a class="nav-link" href="/career">Career History</a>
            </li>
            <li class={props.page === "portfolio" ? "nav-item active" : "nav-item"}>
              <a class="nav-link" href="/portfolio">Portfolio</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/#contact">Contact</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;

import React from "react";
import Navbar from "./Navbar";

function TitleSection(props) {

  let title = ""
  let titleTextOne = ""
  let titleTextTwo = ""
  let titleTextThree = ""

  switch (props.page) {
    case "index":
      title = "James Fitch"
      titleTextOne = "Hi there, my name is James. I am a Technical Business Analyst / Business Performance Manager and People Leader with extensive experience in business process design, improvement, and the implementation of key IT systems."
      titleTextTwo = "I have a passion for problem solving and continuous improvement. I love using technology and automation to eliminate repetitive tasks so that I can focus on more strategic tasks."
      titleTextThree = "Outside of this I have a passion for Computer Science, enjoying learning new programming languages and building out featured platforms. You can check out some of my handy-work here"
      break;
    case "career":
      title = "Career Highlights"
      titleTextOne = "During my career I have been constantly been promoted to areas within businesses. This is due to my hard-working ethic and desire to keep learning new skills. In addition, this is thanks to some amazing colleagues and mentors who have taken the time to develop my skills which I have endeavoured to pay forward."
      titleTextTwo = "Have a read below of my current experience. If you want to skip ahead to a particular section you can do that too:"
      titleTextThree = "<ul><li>Go to <a href='#work' alt='work'>my career timeline</a></li><li>Go to <a href='#volunteer' alt='volunteer'>my volunteer work</a></li><li>Or you can just download my CV. I have a minified version <a href='docs/James_Fitch_Curriculum_Vitae_Slim.pdf' alt='CV Slim.pdf'>here</a> and a more detailed version <a href='docs/James_Fitch_Curriculum_Vitae_Long.pdf' alt='CV Long.pdf'>here</a>.</li></ul>"
      break;
    case "portfolio":
      title = "Portfolio and Body of Work"
      titleTextOne = "I have demonstrable experience in web and iOS development through different different languages. Feel free to check out some of my projects below. If the source code is not linked and you would like to view it feel fee to get <a href='/#contact' alt='contact'>in touch</a>."
      break;
    default:
      console.log("undefined page in switch " + props.page);
      break;
	}
  return (
    <div>
      <section class="colored-section" id="title">
        <div class="container-fluid">
          <Navbar page={props.page}/>
          <div class="container">
            <div class="row">
              <div class="col-lg-10">
                <h1 class="big-heading">{title}</h1>
                <p class="p-heading"dangerouslySetInnerHTML={{__html: titleTextOne}}></p>
                <p class="p-heading"dangerouslySetInnerHTML={{__html: titleTextTwo}}></p>
                <p class="p-heading" dangerouslySetInnerHTML={{__html: titleTextThree}}></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TitleSection;

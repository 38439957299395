import React from "react";

function Footer(props) {
  return (
    <div>
      <footer class="colored-section" id="footer">
        <div class="container-fluid">
          <a href="https://www.linkedin.com/in/james-fitch-0a4146b0/" target="_blank" rel="noopener noreferrer"><i class="social-icon fab fa-linkedin fa-2x"></i></a>
          <a href="/#contact"><i class="social-icon fas fa-envelope fa-2x"></i></a>
          <a href="https://github.com/fitchatron" target="_blank" rel="noopener noreferrer"><i class="social-icon fab fa-github-square fa-2x"></i></a>
          <p><small>© Copyright {props.year} James Fitch</small></p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

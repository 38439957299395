import React from "react";

function Testimonials() {
  return (
    <div>
      <section class="colored-section" id="testimonials">
        <div id="testimonial-carousel" class="carousel slide" data-ride="false">
          <div class="carousel-inner">
            <div class="carousel-item active container-fluid">
              <h2 class="testimonial-text">I lie awake at night imagining what I would do without him</h2>
              <em>Anthony, Former Manager</em>
            </div>
            <div class="carousel-item container-fluid">
              <h2 class="testimonial-text">I thought I knew how to code until I met James</h2>
              <em>Chris, Former Direct Report</em>
            </div>
            <div class="carousel-item container-fluid">
              <h2 class="testimonial-text">I learnt so much from James. He as a really nurturing mentor</h2>
              <em>Kevin, Former Direct Report</em>
            </div>
            <div class="carousel-item container-fluid">
              <h2 class="testimonial-text">I wanted to say something nice but James said he needed something to balance out the testimonials</h2>
              <em>Ali, Former Colleague</em>
            </div>
          </div>
          <a class="carousel-control-prev" href="#testimonial-carousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon"></span>
          </a>
          <a class="carousel-control-next" href="#testimonial-carousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon"></span>
          </a>
        </div>
      </section>
    </div>
  );
}

export default Testimonials;

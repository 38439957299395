import React from "react";
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Index from "./index/Index";
import Career from "./career/Career";
import Portfolio from "./portfolio/Portfolio";

import * as ROUTES from '../constants/routes';

function App() {
  return (
    <Router>
    <div>
      <Route exact path={ROUTES.INDEX} component={Index} />
      <Route exact path={ROUTES.CAREER} component={Career} />
      <Route exact path={ROUTES.PORTFOLIO} component={Portfolio} />
    </div>
    </Router>
  );
}

export default App;

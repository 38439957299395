import React from 'react';

function SourceButton(props) {
  return (
    <div class="pad-vertical">
      <a class='btn btn-outline-dark' href={props.source.url} target='_blank' rel='noopener noreferrer' role='button'>
        {props.source.buttonTitle}
      </a>
    </div>
  );
}

function AppStoreButton(props) {
  return (
    <div>
      <a href={props.source.url} target='_blank' rel='noopener noreferrer'>
        <img src='img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' alt=''/>
        </a>
    </div>
  );
}

function MediaView(props) {
  return (
    <div>
      <div class="embed-responsive embed-responsive-16by9">
        <video class="embed-responsive-item" height="240" controls>
          <source src={props.src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  )
}

function Skill(props) {
  return(
    <div class="skill-container">
      <div class="skill-alert">{props.skill}</div>
    </div>
  );
}

function Project(props) {
  return (
    <div class="row">
      <div class={props.alignment === "leading" ? "col-md-7" : "col-md-7 order-md-2"}>
        <h2 class="portfolio-heading">{props.item.heading}<br /><span class="text-muted">{props.item.subHeading}</span></h2>
        {props.item.skills.map((skill, index) => {
          return (
            <Skill skill={skill} />
          );
        })}
        <p dangerouslySetInnerHTML={{__html: props.item.description}}></p>
        {props.item.sourceURLs.map((source, index) => {
          return (source.buttonTitle === "Download on the App Store" ? <AppStoreButton source={source} /> : <SourceButton source={source} />)
        })}
      </div>
      <div class={props.alignment === "leading" ? "col-md-5" : "col-md-5 order-md-1"}>
        {props.item.mediaURLs.map((url, index) => {
          return (<MediaView src={url} />)
        })}
      </div>
    </div>
  );
}

export default Project;

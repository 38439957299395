import React, { useState } from "react";
import firebase from "../../firebase";
import Recaptcha from "react-recaptcha";

function Contact() {

  // create a variable to store the component instance
  let recaptchaInstance;

  const dateReceived = new Date();
  // eslint-disable-next-line
  const [needsVerification, setVerify] = useState(true);

  const [formStatus, setStatus] = useState({
    success: false,
    failure: false
  });

  const [enquiry, setEnquiry] = useState({
      answered: false,
      dateReceived: dateReceived,
      contactEmail: "",
      contactMessage: "",
      contactName: ""
    });

  function handleChange(event) {
    const { name, value } = event.target;

    setEnquiry(prevEnquiry => {
      return {
        ...prevEnquiry,
        [name]: value
      };
    });
  }

  function handlePostEnquiry(event) {
    const db = firebase.firestore();
    const enquiryRef = db.collection("enquiries").doc();
    enquiryRef
    .set(enquiry)
    .then(
      setStatus({
        success: true,
        failure: false
      })
    )
    .then(
      setEnquiry({
        answered: false,
        dateReceived: dateReceived,
        contactEmail: "",
        contactMessage: "",
        contactName: ""
      }),
      setVerify(true),
      resetRecaptcha()
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (needsVerification) {
      setStatus({
        success: false,
        failure: true
      });
    } else {
      handlePostEnquiry(event);
    }
  }

  function recaptchaLoaded() {
    console.log("recaptcha loaded successfully");
  }

  function verifyCallback(response) {
    if (response) {
      setVerify(false);
    }
  }

  // create a reset function
  const resetRecaptcha = () => {
    recaptchaInstance.reset();
  };

  return (
    <div>
      <section class="white-section" id="contact">
        <div class="container-fluid">
          <h3 class="big-heading">Get in touch</h3>
          <p>Interested in what I can offer your organisation? Got a freelance project you'd like to run by me?</p>
          <p>I'd love to hear from you. Fill out the form below and I'll be in touch.</p>
          <form id="enquiryForm" onSubmit={handleSubmit}>
            <div class="form-group">
              <label for="contactName">Name:</label>
              <input type="text" class="form-control" name="contactName" placeholder="Enter name" onChange={handleChange} value={enquiry.contactName} required />
            </div>
            <div class="form-group">
              <label for="contactEmail">Email Address:</label>
              <input type="email" class="form-control" name="contactEmail" aria-describedby="emailHelp" placeholder="Enter email" onChange={handleChange} value={enquiry.contactEmail} required />
              <small id="emailHelp" class="form-text text-muted">I'll never share your email with anyone else - promise.</small>
            </div>
            <div class="form-group">
              <label for="contactMessage">Message:</label>
              <textarea class="form-control" name="contactMessage" rows="5" placeholder="Enter your message" onChange={handleChange} value={enquiry.contactMessage} required></textarea>
            </div>
            <div class="form-group">
            <Recaptcha
              ref={ref => recaptchaInstance = ref}
              sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
              render="explicit"
              onloadCallback={recaptchaLoaded}
              verifyCallback={verifyCallback}
            />
            </div>
            <button id="contact-btn-submit" type="submit" class="btn btn-primary btn-block" disabled={needsVerification} >Submit</button>
            <div class="form-group pad-top">
              <div class={formStatus.failure ? "alert alert-danger" : "alert alert-danger hidden"} role="alert">Error. Please try again</div>
              <div class={formStatus.success ? "alert alert-success" : "alert alert-success hidden"} role="alert">Thank you - your request has been submitted. I'll be in touch shortly</div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Contact;

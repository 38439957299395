import React from 'react';
import CompanySection from './CompanySection';
import roles from './roles'
import volunteerRoles from './volunteerRoles';

function CareerHistory(props) {

  if (props.section === "Career History") {
    return (
      <div>
        <section class="white-section pad-bottom" id="work">
          <div class="container pad-top">
            <h2>{props.section}</h2>
            {roles.map((role, index) => {
              return (
                <div>
                  <CompanySection company={role} />
                  {index + 1 !== roles.length ? <hr class="portfolio-divider" /> : null}
                </div>
              );
            })}
          </div>
        </section>
      </div>
    )
  } else {
    return (
      <div>
        <section class="white-section pad-bottom" id="volunteer">
          <div class="container pad-top">
            <h2>{props.section}</h2>
            {volunteerRoles.map((volunteerRole, index) => {
              return (
                <div>
                  <CompanySection company={volunteerRole} />
                  {index + 1 !== volunteerRoles.length ? <hr class="portfolio-divider" /> : null}
                </div>
              );
            })}
          </div>
        </section>
      </div>
    )
  }
}

export default CareerHistory;

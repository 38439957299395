import React from 'react';

function RoleSection(props) {
  return (
    <div>
      <h4 class="exp-role-heading">{props.role.title}</h4>
      <h6>{props.role.period}</h6>
      <p>{props.role.description}</p>
      <button class="btn btn-outline-dark" type="button" data-toggle="collapse" data-target={"#"+props.role.id} aria-expanded="false" aria-controls={props.role.id}>Click here to learn more...</button>
      <div class="collapse pad-top" id={props.role.id}>
        <div class="card card-body">
          <h5 class="exp-role-heading">Key Responsibilities</h5>
          <p>
            <ul class="exp-list">
              {props.role.responsibilities.map((responsibility, index) => {
              return (
                <li>{responsibility}</li>
              );
            })}
            </ul>
          </p>
          <h5 class="exp-role-heading">Key Acheivements</h5>
          <p>
            <ul class="exp-list">
              {props.role.achievements.map((achievement, index) => {
              return (
                <li>{achievement}</li>
              );
            })}
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default RoleSection;

import React, { useEffect } from "react";
import TitleSection from "../TitleSection";
import CareerHistory from "./CareerHistory";
import Footer from "../Footer";
import { getYear } from "../../date";

function Career() {
  useEffect(() => {
    document.title = "Career History";
  }, []);

  return (
    <div>
      <TitleSection
        page="career"
      />
      <CareerHistory
        section="Career History"
      />
      <hr class="portfolio-divider" />
      <CareerHistory
        section="Volunteer Work"
      />
      <Footer
        year={getYear()}
      />
    </div>
  );
}

export default Career;

import React from "react";

function Skills() {
  return (
    <div>
      <section class="white-section" id="skills">
        <div class="container-fluid">
          <div class="row">
            <div class="skills-box col-lg-4">
              <i class="icon fas fa-check-circle fa-4x"></i>
              <h3>Stakeholder Management</h3>
              <p>Excellent at developing and maintaining interpersonal relationships and influencing cross-functional teams to deliver on organisational objectives.</p>
            </div>
            <div class="skills-box col-lg-4">
              <i class="icon fas fa-tasks fa-4x"></i>
              <h3>Project</h3>
              <p>Skilled contributor to projects through an Analyst or Project Management perspective. Can run with a standard defined PMP Project, however would prefer to spice things up with continuous delivery through an agile framework such as SCRUM
                or Kanban.</p>
            </div>
            <div class="skills-box col-lg-4">
              <i class="icon fas fa-building fa-4x"></i>
              <h3>Organisational</h3>
              <p>Identifying business priorities, translating those priorities to actionable tasks to drive results? Don't worry I've gt you covered.</p>
            </div>
            <div class="skills-box col-lg-4">
              <i class="icon fas fas fa-code fa-4x"></i>
              <h3>Technical</h3>
              <p>Swift, HTML, CSS, JavaScript/jQuery, Node, Express JS, React, SQL, Tableau, Python, VBA .NET.</p>
              <p>Check out my portfolio to see some of these skills in action. Is there a language or skill you would like to see on this list? Don't worry, I can pick up new code bases quite easily.</p>
            </div>
            <div class="skills-box col-lg-4">
              <i class="icon fas fa-bullseye fa-4x"></i>
              <h3>Leadership</h3>
              <p>Proven skills leading effective teams and people development.</p>
            </div>
            <div class="skills-box col-lg-4">
              <i class="icon fas fa-heart fa-4x"></i>
              <h3>Guaranteed to work</h3>
              <p>Just a really great addition to any organisation - or your money back!</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 center-with-padding">
              <img class="img-fluid rounded" src="img/title-img-1.jpg" alt="" />
            </div>
            <div class="col-lg-6 center-with-padding">
              <img class="img-fluid rounded" src="img/title-img-2.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Skills;
